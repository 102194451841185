import { useStaticQuery, graphql } from "gatsby"

export const useTeams = () => {
  const { allTeamsJson } = useStaticQuery(graphql`
  query {
    allTeamsJson {
      nodes {
        facebook
        fullName
        instagram
        shortName
        storeSite
        twitter
        website
        youtube
        slug
      }
    }
  }
  `);

  return allTeamsJson.nodes;
}
