import adanaDemirspor from "../images/logos/adana-demirspor-logo.png";
import alanyaspor from "../images/logos/alanyaspor-logo.png";
import antalyaspor from "../images/logos/antalyaspor-logo.png";
import basaksehir from "../images/logos/basaksehir-logo.png";
import besiktas from "../images/logos/besiktas-logo.png";
import bodrumspor from "../images/logos/bodrumspor-logo.png";
import eyupspor from "../images/logos/eyupspor-logo.png";
import fenerbahce from "../images/logos/fenerbahce-logo.png";
import galatasaray from "../images/logos/galatasaray-logo.png";
import gaziantepFK from "../images/logos/gaziantep-fk-logo.png";
import goztepe from "../images/logos/goztepe-logo.png";
import hatayspor from "../images/logos/hatayspor-logo.png";
import kasimpasa from "../images/logos/kasimpasa-logo.png";
import kayserispor from "../images/logos/kayserispor-logo.png";
import konyaspor from "../images/logos/konyaspor-logo.png";
import sivasspor from "../images/logos/sivasspor-logo.png";
import trabzonspor from "../images/logos/trabzonspor-logo.png";
import rizespor from "../images/logos/rizespor-logo.png";
import samsunspor from "../images/logos/samsunspor-logo.png";

export const logos = {
  "adana-demirspor": adanaDemirspor,
  alanyaspor: alanyaspor,
  antalyaspor: antalyaspor,
  basaksehir: basaksehir,
  besiktas: besiktas,
  bodrumspor: bodrumspor,
  eyupspor: eyupspor,
  fenerbahce: fenerbahce,
  galatasaray: galatasaray,
  "gaziantep-fk": gaziantepFK,
  goztepe: goztepe,
  hatayspor: hatayspor,
  kasimpasa: kasimpasa,
  kayserispor: kayserispor,
  konyaspor: konyaspor,
  sivasspor: sivasspor,
  trabzonspor: trabzonspor,
  samsunspor: samsunspor,
  rizespor: rizespor,
};

export const logosForFullName = {
  "Adana Demirspor": adanaDemirspor,
  Alanyaspor: alanyaspor,
  Antalyaspor: antalyaspor,
  Başakşehir: basaksehir,
  Beşiktaş: besiktas,
  Bodrumspor: bodrumspor,
  Eyüpspor: eyupspor,
  Fenerbahçe: fenerbahce,
  Galatasaray: galatasaray,
  "Gaziantep FK": gaziantepFK,
  Göztepe: goztepe,
  Hatayspor: hatayspor,
  Kasımpaşa: kasimpasa,
  Kayserispor: kayserispor,
  Konyaspor: konyaspor,
  Sivasspor: sivasspor,
  Trabzonspor: trabzonspor,
  Samsunspor: samsunspor,
  Rizespor: rizespor,
};
