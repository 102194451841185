exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fixture-index-js": () => import("./../../../src/pages/fixture/index.js" /* webpackChunkName: "component---src-pages-fixture-index-js" */),
  "component---src-pages-fixture-rounds-rounds-json-value-js": () => import("./../../../src/pages/fixture/rounds/{RoundsJson.value}.js" /* webpackChunkName: "component---src-pages-fixture-rounds-rounds-json-value-js" */),
  "component---src-pages-fixture-teams-teams-json-slug-js": () => import("./../../../src/pages/fixture/teams/{TeamsJson.slug}.js" /* webpackChunkName: "component---src-pages-fixture-teams-teams-json-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-matches-matches-json-fields-slug-js": () => import("./../../../src/pages/matches/{MatchesJson.fields__slug}.js" /* webpackChunkName: "component---src-pages-matches-matches-json-fields-slug-js" */),
  "component---src-pages-referees-js": () => import("./../../../src/pages/referees.js" /* webpackChunkName: "component---src-pages-referees-js" */),
  "component---src-pages-standings-js": () => import("./../../../src/pages/standings.js" /* webpackChunkName: "component---src-pages-standings-js" */),
  "component---src-pages-team-statistics-js": () => import("./../../../src/pages/teamStatistics.js" /* webpackChunkName: "component---src-pages-team-statistics-js" */),
  "component---src-pages-team-statistics-stat-names-json-slug-js": () => import("./../../../src/pages/teamStatistics/{StatNamesJson.slug}.js" /* webpackChunkName: "component---src-pages-team-statistics-stat-names-json-slug-js" */),
  "component---src-pages-teams-js": () => import("./../../../src/pages/teams.js" /* webpackChunkName: "component---src-pages-teams-js" */),
  "component---src-pages-teams-teams-json-slug-js": () => import("./../../../src/pages/teams/{TeamsJson.slug}.js" /* webpackChunkName: "component---src-pages-teams-teams-json-slug-js" */)
}

