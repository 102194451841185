import React from "react";
import { MdOutlineDarkMode } from "react-icons/md";
import { MdDarkMode } from "react-icons/md";

export default function ThemeToggle({ theme, toggleTheme }) {
  const iconClassName = "text-white cursor-pointer";
  return (
    <>
      {theme === "light" && (
        <MdOutlineDarkMode
          className={iconClassName}
          onClick={() => toggleTheme("dark")}
        />
      )}
      {theme === "dark" && (
        <MdDarkMode
          className={iconClassName}
          onClick={() => toggleTheme("light")}
        />
      )}
    </>
  );
}
