const slugifgy = require('slugify');

function getTeamURL(fullTeamName) {
  return "/takimlar/" + slugifgyTeamName(fullTeamName) + "/";
}

function slugifgyTeamName(fullTeamName) {
  return slugifgy(fullTeamName, { lower: true });
}

function getMatchURL(home, away) {
  return "/maclar/" + slugifgyTeamName(home) + "-"+slugifgyTeamName(away)+"/" ;
}

export { getTeamURL, slugifgyTeamName, getMatchURL };
