import React from "react";
import { Link } from "gatsby";
import {
  FaRegListAlt,
  FaHome,
  FaChartBar,
  FaUsers,
  FaIdBadge,
  FaRegCalendarAlt,
  FaArrowLeft,
} from "react-icons/fa/";

const MenuItem = ({ path, closeMenu, children }) => (
  <Link
    to={path}
    onClick={closeMenu}
    className="border-0 border-b-2 border-indigo-400 pt-8"
  >
    {children}
  </Link>
);

const MenuName = ({ children, Icon = null }) => (
  <div className="m-1 ml-8 flex text-black dark:text-white">
    {Icon && (
      <div className="h-8 w-8 pt-1">
        <Icon className="fill-indigo-600" />
      </div>
    )}
    {children}
  </div>
);

const MenuBar = (props) => {
  return (
    <div className="lg:hidden h-full w-full mt-8">
      <div className="flex z-10 flex-col w-full bg-white text-white font-bold dark:bg-gray-900 pt-20">
        <MenuItem path="/" {...props}>
          <MenuName Icon={FaHome}>Ana Sayfa</MenuName>
        </MenuItem>
        <MenuItem path="/takim-istatistikleri/" {...props}>
          <MenuName Icon={FaChartBar}>Takım İstatistikleri</MenuName>
        </MenuItem>
        <MenuItem path="/fikstur/" {...props}>
          <MenuName Icon={FaRegCalendarAlt}>Fikstür ve Sonuçlar</MenuName>
        </MenuItem>
        <MenuItem path="/puan-durumu/" {...props}>
          <MenuName Icon={FaRegListAlt}>Puan Durumu</MenuName>
        </MenuItem>
        <MenuItem path="/takimlar/" {...props}>
          <MenuName Icon={FaUsers}>Takımlar</MenuName>
        </MenuItem>
        <MenuItem path="/hakemler/" {...props}>
          <MenuName Icon={FaIdBadge}>Hakemler</MenuName>
        </MenuItem>
        <MenuItem path="https://2023-24.ensuperlig.com/" {...props}>
          <MenuName Icon={FaArrowLeft}>2023-24 sezonu</MenuName>
        </MenuItem>
      </div>
    </div>
  );
};

export default MenuBar;
