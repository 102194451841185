import React from "react";
import { Link } from "gatsby";

import ThemeToggle from "./ThemeToggle";
import Logos from "./Logos";
import { logos } from "../util/logos";
import { getTeamURL } from "../util/urlUtil";
import { useTeams } from "../hooks/useTeams";

const StyledLink = ({ path, children, target }) => {
  return (
    <li className="list-none">
      <Link
        to={path}
        className="font-medium w-40 mx-1 text-xs xl:text-sm xl:mx-4 mr-4 text-white no-underline hover:border-0 hover:pb-3 hover:border-b-4 hover:border-pink-500 hover:border-solid"
        activeClassName="border-0 pb-3 border-b-4 border-pink-500 border-solid"
        target={target}
      >
        {children}
      </Link>
    </li>
  );
};

const Navbar = ({ theme, toggleTheme }) => {
  const teams = useTeams();
  const sorted = teams.sort((a, b) => a.fullName.localeCompare(b.fullName));
  return (
    <div className="hidden lg:flex flex-col relative">
      <div className="flex w-full items-center justify-center h-12 bg-white dark:bg-gray-900">
        {sorted.map((team) => {
          return (
            <Link
              key={team.slug}
              className="mx-1 w-7 h-7 rounded-full"
              to={getTeamURL(team.fullName)}
            >
              <img
                width="%100px"
                height="%100px"
                src={logos[team.slug]}
                alt={team.fullName}
              />
            </Link>
          );
        })}
      </div>
      <Logos className="dark:bg-gray-900" />
      <ul className="lg:flex lg:my-0 lg:items-center lg:w-full lg:bg-ensuperlig-1 lg:h-16">
        <div className="pl-48 w-4/5 flex">
          <StyledLink path="/">Ana Sayfa</StyledLink>
          <StyledLink path="/takim-istatistikleri/">
            Takım İstatistikleri
          </StyledLink>
          <StyledLink path="/fikstur/">Fikstür ve Sonuçlar</StyledLink>
          <StyledLink path="/puan-durumu/">Puan Durumu</StyledLink>
          <StyledLink path="/takimlar/">Takımlar</StyledLink>
          <StyledLink path="/hakemler/">Hakemler</StyledLink>
        </div>
        <div className="w-1/5 flex flex-col items-end gap-2">
          <StyledLink path="https://2023-24.ensuperlig.com/" target="_blank">
            2023-24 sezonu
          </StyledLink>

          <div className="mb-1 w-full flex justify-end sticky mr-4">
            <ThemeToggle theme={theme} toggleTheme={toggleTheme} />
          </div>
        </div>
      </ul>
    </div>
  );
};

export default Navbar;
