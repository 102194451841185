import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Logos = ({ className }) => {
  return (
    <div
      className={`absolute left-0 h-full w-36 flex justify-center items-center bg-white ${className}`}
    >
      <Link className="hidden dark:block" to="/" as={"image"}>
        <StaticImage
          src="../images/logo-white.png"
          alt="ensuperlig"
          placeholder="blurred"
          layout="fixed"
          width={125}
          height={90}
          imgStyle={{ objectFit: "contain" }}
        />
      </Link>
      <Link className="dark:hidden" to="/" as={"image"}>
        <StaticImage
          src="../images/logo.png"
          alt="ensuperlig"
          placeholder="blurred"
          layout="fixed"
          width={125}
          height={90}
          imgStyle={{ objectFit: "contain" }}
        />
      </Link>
    </div>
  );
};

export default Logos;
