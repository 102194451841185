import React from "react";
import { FaBars } from "react-icons/fa/";
import { ThemeToggler } from "gatsby-plugin-dark-mode";
import { Popover } from "@headlessui/react";

import Logos from "./Logos";
import MenuBar from "./MenuBar";
import Navbar from "./Navbar";
import ThemeToggle from "./ThemeToggle";
import Footer from "./Footer";

const Header = ({ children, headerClassName = "" }) => {
  return (
    <header
      className={`sticky top-0 z-20 h-28 w-full bg-ensuperlig-1 ${headerClassName}`}
    >
      {children}
    </header>
  );
};

const RegularContent = ({ theme, toggleTheme, children }) => {
  return (
    <>
      <Header headerClassName="hidden lg:block lg:shrink-0">
        <Navbar theme={theme} toggleTheme={toggleTheme} />
      </Header>
      <main className="hidden mt-8 max-w-screen-2xl w-full lg:flex lg:flex-row lg:grow flex-col bg-white dark:bg-gray-900">
        {children}
      </main>
      <Footer className="hidden lg:flex" />
    </>
  );
};

const MobileContent = ({ theme, toggleTheme, children }) => {
  return (
    <Popover className="w-full flex flex-col grow lg:hidden">
      {({ open, close }) => (
        <>
          <Header headerClassName="relative">
            <button className="absolute top-16 z-40 right-6">
              <ThemeToggle theme={theme} toggleTheme={toggleTheme} />
            </button>
            <Logos className="dark:bg-ensuperlig-1" />
            <Popover.Button>
              <FaBars className="absolute right-6 top-4 lg:hidden w-7 h-7 text-white cursor-pointer" />
            </Popover.Button>
          </Header>
          {open ? (
            <Popover.Panel className="absolute z-5 w-full top-2">
              <MenuBar closeMenu={close} />
            </Popover.Panel>
          ) : (
            <>
              <main className="lg:hidden w-full flex flex-col grow bg-white dark:bg-gray-900">
                {children}
              </main>
              <Footer />
            </>
          )}
        </>
      )}
    </Popover>
  );
};

const Layout = ({ children }) => {
  return (
    <ThemeToggler>
      {({ theme, toggleTheme }) => (
        <div className="flex flex-col items-center w-full min-h-screen dark:bg-gray-900">
          <RegularContent theme={theme} toggleTheme={toggleTheme}>
            {children}
          </RegularContent>
          <MobileContent theme={theme} toggleTheme={toggleTheme}>
            {children}
          </MobileContent>
        </div>
      )}
    </ThemeToggler>
  );
};

export default Layout;
